import React, { ReactElement, useEffect } from "react"
import {
    faDiscord,
    faTwitter,
    faReddit
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import scrollTo from 'gatsby-plugin-smoothscroll';

interface Props {
    darkMode: boolean;
}

export function Footer(props: Props): ReactElement {

    const scrollToTop = () => {
        scrollTo('#container');
    }

    if (props.darkMode) {
        return (
            <>
                <div className="container mx-auto">
                    <footer className="mt-16 mb-4 md:mb-0 mx-4 pb-4 md:mt-32 md:pb-10 pt-4 md:pt-14 footer font-body">
                        <div className="mx-auto flex justify-between flex-col md:flex-row items-center relative">
                            <div className="md:w-1/3 w-full">
                                <a href="/" id="link_home-footer" className="link_home-footer flex items-center text-white md:mb-0">
                                    <img className="object-contain" src="https://cdn-static.ymeet.me/flima/logo.png" width="106" height="32" alt="logo image" />
                                </a>
                                <p className="text-slateGrey mt-2 mb-6 block md:hidden">Copyright © 2022 Flima. All rights reserved.</p>
                            </div>
                            <nav className="md:m-auto flex flex-col md:flex-row md:p-0 w-full md:w-1/3 md:items-center items-start text-base text-cloudyBlue justify-center" id="menu">
                                <a href="/term-of-use" id="link_terms-conditions" className="link_terms-conditions md:w-auto w-full md:mr-10 md:py-0 py-2 text-cloudyBlue hover:no-underline hover:text-pink">Terms & Conditions</a>
                                {/*<a href="https://medium.com/@flimaio" target="_blank" id="footer_blogs" className="footer_blogs md:w-auto w-full md:mr-10 md:py-0 py-2 text-cloudyBlue hover:no-underline hover:text-pink">Blog</a>*/}
                                {/*<a href="/faq-page/" id="link_faqs" className="link_faqs md:w-auto w-full md:mr-10 md:py-0 py-2 text-cloudyBlue hover:no-underline hover:text-pink">FAQs</a>*/}
                            </nav>
                            <div className="flex flex-row w-full md:w-1/3 justify-start md:justify-end mt-6 md:mt-0">
                                <a href="https://discord.gg/c8uNCTZTs5" id="footer_discord" target="_blank" className="footer_discord z-50 md:static p-0 inline-flex items-center font-medium md:bg-blue border-0 mr-8 md:mr-0 text-white md:py-2 md:px-4 focus:outline-none md:mt-0">
                                    <FontAwesomeIcon className="text-2xl text-cloudyBlue hover:text-pink" icon={faDiscord} />
                                </a>
                                <a href="https://twitter.com/Flima_io" id="footer_tweet" target="_blank" className="footer_tweet z-50 md:static p-0 inline-flex items-center font-medium md:bg-blue border-0 text-white mr-8 md:mr-0 md:py-2 md:px-4 md:mt-0">
                                    <FontAwesomeIcon className="text-2xl text-cloudyBlue hover:text-pink" icon={faTwitter} />
                                </a>
                                <a href="https://www.reddit.com/r/Flima_io/" id="footer_reddit" target="_blank" className="footer_reddit z-50 md:static p-0 inline-flex items-center font-medium md:bg-blue border-0 text-white md:py-2 md:pl-4 md:mt-0">
                                    <FontAwesomeIcon className="text-2xl text-cloudyBlue hover:text-pink" icon={faReddit} />
                                </a>
                            </div>
                        </div>
                        <div className="md:block hidden"><p className="text-cloudyBlue mt-2">Copyright © 2022 Flima. All rights reserved.</p></div>
                    </footer>
                </div>
                <svg onClick={scrollToTop} className="scroll-top-btn" width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.201 14.05c0-.609-.236-1.15-.709-1.622l-6.82-6.843a2.061 2.061 0 0 0-.753-.462 2.547 2.547 0 0 0-.913-.172c-.3 0-.605.057-.913.172a2.062 2.062 0 0 0-.752.462L1.51 12.428C1.036 12.9.8 13.44.8 14.05c0 .651.204 1.178.612 1.579.416.4.924.601 1.526.601.358 0 .666-.075.924-.225.265-.15.5-.333.709-.548l2.46-2.481 2.17-2.761L7.964 9.15 7.73 14.19v9.238c0 .73.207 1.314.623 1.75.415.445.967.667 1.654.667.688 0 1.239-.222 1.654-.666.416-.437.623-1.02.623-1.751v-9.239l-.236-5.038-1.225 1.064 2.17 2.76 2.439 2.482c.208.215.44.397.698.548.265.15.577.226.935.226.608 0 1.117-.201 1.525-.602.408-.401.612-.928.612-1.58zm0-11.247c0-.588-.21-1.092-.633-1.515a2.044 2.044 0 0 0-1.515-.634H2.948a2.08 2.08 0 0 0-1.525.634A2.085 2.085 0 0 0 .8 2.803c0 .587.208 1.092.623 1.514a2.08 2.08 0 0 0 1.525.634h14.105c.594 0 1.1-.211 1.515-.634.422-.422.633-.927.633-1.514z" fill="#000" />
                </svg>
            </>
        )
    }
    return (
        <div className="container mx-auto">
            <footer className="mt-8 mb-4 md:mb-0 mx-4 pb-4 md:mt-20 md:pb-10 pt-4 md:pt-14 footer font-body">
                <div className="mx-auto flex justify-between flex-col md:flex-row items-center relative">
                    <div className="md:w-1/3 w-full">
                        <a href="/" id="link_home-footer" className="link_home-footer flex items-center text-white md:mb-0">
                            <img className="object-contain" src="https://cdn-static.ymeet.me/flima/logo_black.png" width="106" height="32" alt="logo image" />
                        </a>
                        <p className="text-slateGrey mt-2 mb-6 md:hidden">Copyright © 2022 Flima. All rights reserved.</p>
                    </div>
                    <nav className="md:m-auto flex flex-col md:flex-row md:p-0 w-full md:w-1/3 md:items-center items-start text-base text-charcoal justify-center" id="menu">
                        <a href="/term-of-use" id="link_terms-conditions" className="link_terms-conditions md:w-auto w-full md:mr-10 md:py-0 py-2 text-charcoal hover:no-underline hover:text-pink">Terms & Conditions</a>
                        {/*<a href="https://medium.com/@flimaio" target="_blank" id="link_blogs" className="link_blogs md:w-auto w-full md:mr-10 md:py-0 py-2 text-charcoal hover:no-underline hover:text-pink">Blog</a>*/}
                        {/*<a href="/faq-page/" id="link_faqs" className="link_faqs md:w-auto w-full md:mr-10 md:py-0 py-2 text-charcoal hover:no-underline hover:text-pink">FAQs</a>*/}
                    </nav>
                    <div className="flex flex-row w-full md:w-1/3 justify-start md:justify-end mt-6 md:mt-0">
                        <a href="https://discord.gg/c8uNCTZTs5" id="link_discord" target="_blank" className="link_discord z-50 p-0 inline-flex items-center font-medium md:bg-blue border-0 text-white text-base md:py-2 md:px-4 mr-8 md:mr-0 hover:text-pink focus:outline-none md:mt-0">
                            <FontAwesomeIcon className="text-2xl text-black hover:text-pink" icon={faDiscord} />
                        </a>
                        <a href="https://twitter.com/Flima_io" id="link_twitter" target="_blank" className="link_twitter z-50 p-0 inline-flex items-center font-medium md:bg-blue border-0 text-white mr-8 md:mr-0 md:py-2 md:px-4 md:mt-0">
                            <FontAwesomeIcon className="text-2xl text-black hover:text-pink" icon={faTwitter} />
                        </a>
                        <a href="https://www.reddit.com/r/Flima_io/" id="link_reddit" target="_blank" className="link_reddit z-50 p-0 inline-flex items-center font-medium md:bg-blue border-0 text-white md:mr-0 md:py-2 md:pl-4 md:mt-0">
                            <FontAwesomeIcon className="text-2xl text-black hover:text-pink" icon={faReddit} />
                        </a>
                    </div>
                </div>
                <div className="md:block hidden">
                    <p className="text-slateGrey mt-2">Copyright © 2022 Flima. All rights reserved.</p>
                </div>
            </footer>
            <svg onClick={scrollToTop} className="scroll-top-btn white" width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.201 14.05c0-.609-.236-1.15-.709-1.622l-6.821-6.843a2.062 2.062 0 0 0-.752-.462 2.546 2.546 0 0 0-.913-.172c-.3 0-.605.057-.913.172a2.062 2.062 0 0 0-.752.462l-6.832 6.843c-.473.472-.71 1.013-.71 1.622 0 .651.205 1.178.613 1.579.415.4.924.601 1.526.601.358 0 .666-.075.923-.225.265-.15.502-.333.71-.548l2.46-2.481 2.17-2.761L7.964 9.15l-.236 5.039v9.238c0 .73.207 1.314.623 1.75.415.445.966.667 1.654.667.687 0 1.239-.222 1.654-.666.415-.437.623-1.02.623-1.751v-9.239l-.236-5.038-1.225 1.064 2.17 2.76 2.439 2.482c.207.215.44.397.698.548.265.15.576.226.934.226.61 0 1.118-.201 1.526-.602.408-.401.612-.928.612-1.58zm0-11.247c0-.588-.211-1.092-.634-1.515a2.044 2.044 0 0 0-1.514-.634H2.948a2.08 2.08 0 0 0-1.525.634A2.085 2.085 0 0 0 .8 2.803c0 .587.207 1.092.623 1.514a2.08 2.08 0 0 0 1.525.634h14.105c.594 0 1.099-.211 1.514-.634.423-.422.634-.927.634-1.514z" fill="#fff" />
            </svg>
        </div>
    )
}